import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-backend-dev.jpg"

const BackendArchitectureContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE ARCHITECTURE DESIGN</span>

              <h3>Backend Architecture</h3>
              <h5>
                Microservices design for backend modules in Java Spring-Boot or
                NodeJS TypeScript technologies. Porting existing application
                from JEE, C++ to Spring-Boot. Domain design for SQL and NoSQL
                databasess. Secure API Design.
              </h5>
              <p>
                Software projects become more and more complex every year. Time
                of complex monolith backend solutions reside in the past. In
                order to stay competitive and be able to manage large software
                solutions companies have had to shift to a more modern approach
                with architecture that can change functionality rapidly and
                withstand enormous load.
              </p>
              <p>
                Modularisation with microservices in backend development is
                usually the answer for complexity. There are however traps along
                the way to good architecture. Traps that can be avoided if a
                project is led by an experienced architect.
              </p>
              <h4>Sizing & Split</h4>
              <p>
                First of all, microservices are great but they also bring a log
                of organisational overhead for the development process. The real
                question is, “When is the size of the project big enough so that
                micro-services will improve overall development efficiency?”
                Early projects that operate on minimum budgets, and building MVP
                are not good for micro-services – best strategy is to start lean
                in monolith and build modules for later target architecture.
                When using additional overhead is reasonable then switch to
                target micro-services architecture.
              </p>
              <h4>Communication</h4>
              <p>
                Second caveat is also proper communication. Very often,
                micro-services use too much synchronous communication instead of
                asynchronous messaging. This brings long latency for calls, and
                too much dependencies to manage in a complex distributed system.
              </p>
              <h4>Testing</h4>
              <p>
                Proper testing is also a challenge. Micro-services have to be
                tested either in complete isolation or in selected subgroups, so
                that communication problems may be spotted early in the
                development cycle. Proper architecture makes this an easier
                task, allowing the use of standard tools and approach for all
                typical test scenarios.
              </p>
              <p>
                Building proper micro-service architecture for big systems is
                like going through a mine field. Sometimes after a few months
                you may find out that you are standing on a mine already. To
                move in any direction can be very painful.
              </p>
              <p>
                Arch Forge Solutions with its extensive experience can help you
                navigate through this minefield.
              </p>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3>
                      Typical software challenges we encounter at customer
                      projects:
                    </h3>
                    <ul>
                      <li>Bad domain boundaries in modules</li>
                      <li>Too many dependencies between modules</li>
                      <li>Too many single points of failure in solution</li>
                      <li>
                        Teams working on separate modules block each other with
                        dependencies
                      </li>
                      <li>
                        Bugs detected too late in development process or first
                        time in production support
                      </li>
                      <li>No base framework for components</li>
                      <li>
                        Improper database use by microservices (no db.
                        separation)
                      </li>
                      <li>
                        Lack of proper global event tracing for issue diagnosis
                      </li>
                      <li>
                        Improper cache implementation in clustered environment
                      </li>
                      <li>
                        Lack of proper security in critical places in code
                      </li>
                      <li>
                        Bad transaction management for distributed processes
                      </li>
                      <li>
                        Improper saga implementations that leave inconsistent
                        data
                      </li>
                      <li>No proper, or no testing automation at all</li>
                      <li>
                        Bad security role and permission model for
                        authorisations
                      </li>
                      <li>
                        Badly designed APIs that aren’t prepared for growth
                      </li>
                      <li>No automated performance testing</li>
                      <li>
                        Improperly implemented CQRS (Command Query
                        Responsibility Separation) concept
                      </li>
                      <li>
                        Using dependencies instead of dedicated cross component
                        data models
                      </li>
                      <li>Lack of decoupled configuration for microservices</li>
                      <li>Concept design errors with time dependent data</li>
                      <li>Domain model leakage across system boundaries</li>
                    </ul>
                  </div>
                </div>
              </div>

              <p>
                If you feel that you suffer in your projects due to any of these
                points, we can help.
              </p>

              <div className="content">
                <h4 className="pt-30">
                  What we can do to solve those challenges:
                </h4>
                <ul>
                  <li>
                    Analyse requirements for an entirely new system or an
                    extension of it, and prepare a set of tailored design
                    deliverables
                  </li>
                  <li>
                    Analyse existing architecture and propose an architecture
                    transformation roadmap to fix most painful business problems
                    first
                  </li>
                  <li>
                    Transform solution architecture to move from software
                    individual project to customisable standardised product
                  </li>
                  <li>
                    Transform solution architecture to move from MVP to global
                    multi-tenant SaaS product
                  </li>
                  <li>Perform a backend security check and prepare fix plan</li>
                  <li>
                    Design and prepare migration plan for Go-Live cut off and
                    help to execute it
                  </li>
                </ul>
              </div>

              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use in our backend
                projects.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>Spring-Boot</span>
                </li>
                <li>
                  <span>NodeJS</span>
                </li>
                <li>
                  <span>Redis</span>
                </li>
                <li>
                  <span>Quarcus</span>
                </li>
                <li>
                  <span>GraalVM</span>
                </li>
                <li>
                  <span>Kafka</span>
                </li>
                <li>
                  <span>RabotMQ</span>
                </li>
                <li>
                  <span>Python</span>
                </li>
                <li>
                  <span>Kotlin</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BackendArchitectureContent
