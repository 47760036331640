import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import BackendArchitectureContent from "../components/ServiceDetails/BackendArchitectureContent"

const Details = ({ location }) => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Service Backend Architecture"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Backend Architecture"
        sectionPageText="Architecture Services"
        sectionPageUrl="/services-architecture"
      />
      <BackendArchitectureContent location={location} />
      <Footer />
    </Layout>
  )
}

export default Details
